<template lang="pug">
    div
        template(v-if="userLanguage == 'fr'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 Qu’est-ce qu’un chapitre ?
                p.content(v-if="toggleQuestions[0] === true") 
                    | Un chapitre est un espace au sein duquel les utilisateurs peuvent poster du contenu. 
                    br
                    | Un chapitre est accessible par tous les utilisateurs de la plateforme, chaque utilisateur peut le rejoindre, en voir le contenu et y poster du contenu.
                
            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 Comment créer un chapitre ?
                p.content(v-if="toggleQuestions[1] === true")
                    | Cliquez sur le bouton “Créer” en haut à droite de n’importe quelle page de la plateforme. 
                    br
                    | Cliquez sur « chapitre ». 
                    br
                    | Une pop-up s’ouvre. 
                    br
                    | Renseignez le nom du chapitre (un nom de chapitre unique est requis). 
                    br
                    | Cliquez sur l’icône pour choisir une couleur de chapitre. 
                    br
                    | Renseignez les utilisateurs invités dans le chapitre à l’aide de la barre de recherche d’utilisateur. Il est également possible d’ajouter un chapitre de personnes aillant une même compétence. 
                    br
                    | Confirmez la liste des utilisateurs invités dans le chapitre lors de sa création. 
                    br
                    | Renseignez un message qui sera envoyé par mail aux utilisateurs invités dans le chapitre à sa création (facultatif). 
                    br
                    | Cliquez sur le bouton « Créer » afin de créer ce dernier. 

        template(v-if="userLanguage == 'en'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 What is a chapter?
                p.content(v-if="toggleQuestions[0] === true") 
                    | A chapter is a space where users can post content.
                    br
                    | A chapter is accessible to everyone, every user can join it, post content and see what is posted in a chapter.
                
            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 How to create a chapter?
                p.content(v-if="toggleQuestions[1] === true")
                    | Click the "Create" button at the top right of any page on the platform.
                    br
                    | Click "chapter".
                    br
                    | A pop-up window shows up.
                    br
                    | Fill in the name of the chapter, a unique chapter name is required.
                    br
                    | Choose a chapter color by clicking the icon.
                    br
                    | Use the user search bar to invite users to the chapter. It is also possible to add a chapter of people with specific skills.
                    br
                    | Confirm the list of invited users of the chapter.
                    br
                    | Fill in a message to be sent to invited users of the chapter when the chapter is created (optional).
                    br
                    | Click the "Create" button to create the chapter.

        template(v-if="userLanguage == 'zh-cn'")
            div.question.block.col-9(:class="{activated: toggleQuestions[0] === true}" @click="toggleQuestions[0] = toggleQuestions[0] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[0] === true}")
                p.text-averta-semibold-20 群组是什么？
                p.content(v-if="toggleQuestions[0] === true")
                    | 1. 小组是可以发布内容的地方。
                    br
                    | 2. 小组：每个用户都可以加入小组，发布内容，并查看组中发布的内容。

            div.question.block.col-9(:class="{activated: toggleQuestions[1] === true}" @click="toggleQuestions[1] = toggleQuestions[1] ? false : true;$forceUpdate()")
                img.icon.icon-24.dropdown-icon(src="./../../assets/icon/dropdown-icon.svg" :class="{activated: toggleQuestions[1] === true}")
                p.text-averta-semibold-20 如何新建小组?
                p.content(v-if="toggleQuestions[1] === true")
                    | 1. 点击任意页面右上方的 "创建 "按钮。
                    br
                    | 2. 点击 "群组"。
                    br
                    | 3. 弹出创建窗口。
                    br
                    | 4. 填写小组名称，不能与现有小组名称相同。
                    br
                    | 5. 点击图标选择小组颜色。
                    br
                    | 6. 使用用户搜索栏邀请成员加入小组，也可以添加特定技能来邀请拥有该技能的成员加入小组。
                    br
                    | 7. 确认邀请名单。
                    br
                    | 8. 填入邀请成员加入小组时发送的信息（可选）。
                    br
                    | 9. 点击 "创建 "按钮，完成小组创建。

</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({

    data() {
        return {
            toggleQuestions: []
        }
    },

    computed: {
        userLanguage() {
            return this.$store.getters['language/getUserLanguage']
        }
    }

})
</script>

<style lang="scss" scoped>
@import './question.scss';
</style>